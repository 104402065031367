import React from "react";
import Images from "../../../../utils/styles/images";
import "./style.scss";

function Pagination({
  total = 0,
  pageIndex = 0,
  gotoPage = () => {},
  canNextPage = false,
  canPreviousPage = false,
  nextPage = () => {},
  previousPage = () => {},
}) {
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxVisibleButtons = 4; // Maximum number of buttons to show
    const startPage = Math.max(
      0,
      pageIndex - Math.floor(maxVisibleButtons / 2)
    );
    const endPage = Math.min(total, startPage + maxVisibleButtons);

    for (let i = startPage; i < endPage; i++) {
      buttons.push(
        <button
          key={`page-${i}`}
          className={
            pageIndex === i ? "pagination-button-selected" : "pagination-button"
          }
          onClick={() => gotoPage(i)}
        >
          {i + 1}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="pagination-container">
      <button
        className="pagination-first"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      <button
        className="pagination-previous"
        onClick={previousPage}
        disabled={!canPreviousPage}
      >
        <img src={Images.pagination.leftArrow} alt="left-arrow" />
      </button>
      {renderPaginationButtons()}
      <button
        className="pagination-next"
        onClick={nextPage}
        disabled={!canNextPage}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
      <button
        className="pagination-last"
        onClick={() => gotoPage(total - 1)}
        disabled={!canNextPage}
      >
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
        <img src={Images.pagination.rightArrow} alt="right-arrow" />
      </button>
    </div>
  );
}

export default Pagination;
