import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setFullImgUrl } from "./redux/actions";
import DOMpurify from "dompurify";

function useQueryParam(param) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  return params.get(param);
}

const isValidImageUrl = (url) => {
  const allowedHosts = [
    "th-b2c-s3-file-upload-bucket-uat-ap-southeast-1.s3.ap-southeast-1.amazonaws.com",
  ];
  const allowedExtensions = [".png", ".jpg", ".jpeg"];
  try {
    const parsedUrl = new URL(url);
    return (
      parsedUrl.protocol === "https:" &&
      allowedHosts.includes(parsedUrl.host) &&
      allowedExtensions.some((ext) => parsedUrl.pathname.endsWith(ext)) &&
      !parsedUrl.search // Ensure no query parameters are present
    );
  } catch (e) {
    return false;
  }
};
const FullImage = (props) => {
  const imgUrl = useQueryParam("imgUrl");
  const rotateAngle = useQueryParam("rotate");
  const rotateValue = rotateAngle * 90;

  // ตรวจสอบ imgUrl ว่าถูกต้องหรือไม่
  const sanitizedImgUrl = isValidImageUrl(imgUrl)
    ? DOMpurify.sanitize(imgUrl)
    : null;

  return (
    <div
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <img
        style={{
          width: "auto",
          height: "auto",
          maxWidth: "80vw",
          maxHeight: "80vh",
          objectFit: "contain",
          transform: `rotate(${rotateValue}deg)`,
        }}
        src={imgUrl}
        alt="logo"
      />
    </div>
  );
};
const withConnect = connect(
  (state) => ({
    ...state.untHldDetailReducer,
  }),
  {
    setFullImgUrl,
  }
);
export default compose(withConnect, withRouter)(FullImage);
