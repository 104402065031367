import React, { useState } from "react";

function ExpandableText({ text }) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length > 10) {
    return (
      <div>
        {isExpanded ? (
          <div>
            <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
              {text}
            </pre>
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setIsExpanded(false)}
            >
              {" "}
              see less
            </span>
          </div>
        ) : (
          <span>
            {text.substring(0, 10)}...
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => setIsExpanded(true)}
            >
              {" "}
              see more
            </span>
          </span>
        )}
      </div>
    );
  } else {
    return (
      <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {text}
      </pre>
    );
  }
}

export default ExpandableText;
