import { put, call, all, takeLatest, select } from "redux-saga/effects";
import { setCurrentCustomerUntHldDetail } from "../../UntHldDetail/redux/actions";
import { selectedObjectUuid } from "../../../components/BreadCrumbs/redux/actions";
import {
  REQUntHldListAuto,
  RESUntHldListAuto,
  FailUntHldListAuto,
  // ------DisApprove----
  requestDisapproveClientAuto,
  receiveDisapproveClientAuto,
  failDisapproveClientAuto,
} from "./actions";
import * as actions from "../../Authentication/redux/actions";

// start test
function buildSearchParams(tSrch, tOpSrch) {
  const params = {
    tEmailSrch: "",
    tUUIDSrch: "",
    tUserSrch: "",
    tNameENSrch: "",
    tNameTHSrch: "",
    tIDNoSrch: "",
    tMobileSrch: "",
  };

  switch (tOpSrch) {
    case "Email":
      params.tEmailSrch = tSrch;
      break;
    case "Username":
      params.tUserSrch = tSrch;
      break;
    case "UUID":
      params.tUUIDSrch = tSrch;
      break;
    case "English Name":
      params.tNameENSrch = tSrch;
      break;
    case "Thai Name":
      params.tNameTHSrch = tSrch;
      break;
    case "ID No. / Passport No.":
      params.tIDNoSrch = tSrch;
      break;
    case "Mobile":
      params.tMobileSrch = tSrch;
      break;
    default:
      break;
  }

  return params;
}

function* fetchUntHldListWorker({ payload }) {
  try {
    const accessToken = yield select((state) => state.auth.accessToken);
    const tGUID = yield select((state) => state.auth.GUID);
    const {
      page,
      tSrch,
      tOpSrch,
      FltDocSta,
      FltAccSta,
      FltConvertUsr,
      SortH,
      status,
      status_method,
    } = payload;

    const searchParams = buildSearchParams(tSrch, tOpSrch);
    let tFltDocSta = FltDocSta;
    let tFltAccSta = FltAccSta;
    let tFltConvertUsr = FltConvertUsr;
    let tSortH = SortH;
    const request = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/autoapprove?page=${page}`,
      // `${process.env.REACT_APP_API_LOCAL}/api/v1/admin-actions/autoapprove?page=${page}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          userid: tGUID,
          id_token: "Bearer " + accessToken,
        },
        body: JSON.stringify({
          ...searchParams,
          FltStaCode: tFltAccSta,
          FltDocStaCode: tFltDocSta,
          FltConVertUsr: tFltConvertUsr,
          sortH: tSortH,
          status: status,
          status_method: status_method,
        }),
      }
    );
    if (request.status === 401) {
      yield put(actions.autoSignOut());
      return;
    }
    const response = yield request.json();
    console.log("admin-actions/autoapprove:", response);
    if (response) {
      yield put(RESUntHldListAuto(response));
    } else {
      yield put(RESUntHldListAuto());
    }
  } catch (error) {
    yield put(FailUntHldListAuto());
  }
}
// end test
function* fetchDisapproveClient({ payload }) {
  // Takes an existing Approved client, and resets them to Pending status
  try {
    const { customerUuid, unitholderid, firstName, UserName, history } =
      payload;
    const accessToken = yield select((state) => state.auth.accessToken);
    const tGUID = yield select((state) => state.auth.GUID);
    const body = {
      statusCode: "1",
    };
    console.log("API payload Disapprove", payload);
    const response = yield fetch(
      `${process.env.REACT_APP_API_ADMIN_URL}/api/v1/admin-actions/customers/${customerUuid}/status`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": `application/json`,

          userid: tGUID,
          id_token: "Bearer " + accessToken,
        },
        body: JSON.stringify(body),
      }
    ).then(async (res) => {
      console.log(await res.json());
      return res;
    });
    if (response.status === 401) {
      yield put(actions.autoSignOut());
      return;
    }
    if (response && response.data && response.data.data) {
      yield put(
        setCurrentCustomerUntHldDetail({
          uuid: customerUuid,
          unitholderid: unitholderid,
          UserName: UserName,
        })
      );
      yield put(selectedObjectUuid(firstName));
      yield put(receiveDisapproveClientAuto());
      history.push(
        `/UnitHolderDetail/${customerUuid}/UnitHolderID=${unitholderid}`
      );
    } else {
      yield put(failDisapproveClientAuto());
    }
  } catch (error) {
    yield put(failDisapproveClientAuto());
  }
}

export function* untHldListAutoSaga() {
  yield all([
    takeLatest(REQUntHldListAuto, fetchUntHldListWorker),
    takeLatest(requestDisapproveClientAuto, fetchDisapproveClient),
  ]);
}
