export const list_of_columns = [
  {
    Header: "NO.",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 5,
    width: 40,
  },
  {
    Header: "DEVICE NAME",
    accessor: "deviceName",
    disableSortBy: true,
    textAlign: "left",
    width: 140,
    paddingLeft: 0,
  },
  {
    Header: "USERNAME",
    accessor: "userName",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 20,
    width: 140,
  },
  {
    Header: "CREATED AT",
    accessor: "createdAt",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 10,
    width: 150,
  },
  {
    Header: "APP VERSION",
    accessor: "appVersion",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 5,
    width: 100,
  },
  {
    Header: "APP PLATFORM",
    accessor: "appPlatform",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 10,
    width: 120,
  },
  {
    Header: "FIREBASE ID",
    accessor: "firebaseFid",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 10,
    width: 205,
  },
  {
    Header: "ACTIVITY TYPE",
    accessor: "activityType",
    disableSortBy: true,
    textAlign: "left",
    paddingLeft: 10,
    width: 130,
  },
  {
    Header: "ELEMENT NAME",
    accessor: "element_name",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 10,
  },
  {
    Header: "SCREEN SECTION",
    accessor: "screen_section",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 10,
  },
  {
    Header: "SCREEN NAME",
    accessor: "screen_name",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 10,
  },
  {
    Header: "ELEMENT LOCATION",
    accessor: "element_location",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 10,
  },
  {
    Header: "ELEMENT TYPE",
    accessor: "element_type",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 10,
  },
  {
    Header: "ELEMENT STATUS",
    accessor: "element_status",
    disableSortBy: true,
    textAlign: "left",
    width: 150,
    paddingLeft: 10,
  },
  {
    Header: "EXPORT",
    display: "",
    disableSortBy: true,
    textAlign: "left",
    width: 80,
    paddingLeft: 10,
  },
];
