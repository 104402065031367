import React from "react";
import "./style.scss";

export default function BasicTextArea({
  type = "text",
  placeholder = "",
  disabled = false,
  value = "",
  onChange = () => {},
  className = "",
  sx = {},
}) {
  return (
    <div
      className={`input-wrapper ${disabled ? "disabled" : ""} ${className}`}
      style={{ ...sx }}
    >
      <textarea
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        className="input-box"
      />
    </div>
  );
}
