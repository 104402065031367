import React, { Fragment, useEffect, useState } from "react";
import Pagination from "./pagination";
import { useTable, usePagination } from "react-table";
import "./style.scss";
import { modifyCell } from "./cell-formatter";

function TableHistoryLogPagination({
  columns = [],
  data = { items: [], _meta: { total: 0, limit: 10, start: 0 } },
  onPageChange,
}) {
  // Ensure `meta` has valid defaults
  const meta = data.meta || { total: 0, limit: 10, start: 0 };

  // Ensure `pageData` is always an array
  const pageData = Array.isArray(data.items) ? data.items : [];

  const totalPages = Math.ceil(meta.total / meta.limit);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    state: { pageIndex },
    gotoPage,
  } = useTable(
    {
      columns,
      data: pageData, // Pass sanitized pageData
      manualPagination: true,
      pageCount: totalPages,
      initialState: { pageIndex: Math.floor(meta.start / meta.limit) },
    },
    usePagination
  );

  // Function to handle page change
  const handlePageChange = (newPage) => {
    if (pageIndex !== newPage) {
      onPageChange(newPage); // Call the main page's handler
      gotoPage(newPage); // Update pageIndex in the table
    } else {
      console.log("PageIndex is already at:", pageIndex);
    }
  };

  return (
    <div className="table-list-container">
      <table className="table" {...getTableProps()}>
        <thead className="table-header-container">
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="table-header-row"
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} className="table-header-cell">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="table-body" {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length} className="no-data-cell">
                No data available
              </td>
            </tr>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="table-row">
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className={`table-cell-${cell.column.id}`}
                    >
                      {/* {cell.render("Cell")} */}
                      {modifyCell(cell.render("Cell"))}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      <Pagination
        total={totalPages}
        pageIndex={pageIndex}
        gotoPage={handlePageChange}
        canNextPage={pageIndex < totalPages - 1}
        canPreviousPage={pageIndex > 0}
        nextPage={() => handlePageChange(pageIndex + 1)}
        previousPage={() => handlePageChange(pageIndex - 1)}
      />
    </div>
  );
}

export default TableHistoryLogPagination;
