import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  requestUntHldDetailDocuments,
  FETCH_UPLOAD_FILE_TO_S3_REQUEST,
  FETCH_SAVE_TO_DB_REQUEST,
  RESET_UPLOAD_FILE_TO_S3,
  FETCH_UPLOAD_FILE_TO_S3_SUCCESS,
  savingtodb,
  uploadingToS3,
} from "./redux/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { select } from "redux-saga/effects";

const documentNameMapping = {
  1: "Thai ID Front",
  2: "Thai ID Back",
  4: "Bankbook",
  7: "Passport",
  8: "Profile Picture",
  9: "Selfie With ID (NDID / Counter Service)",
  10: "ID card 7-11",
};

const documentTypeMapping = {
  1: "front",
  2: "back",
  4: "bankbook",
  7: "passportFront",
  8: "profile",
  9: "selfie",
  10: "imageOnIdCard",
};

const ManualUpload = (props) => {
  const {
    customerUuid,
    documentTypeCode,
    documentStatusCode,
    FETCH_UPLOAD_FILE_TO_S3_REQUEST,
    FETCH_UPLOAD_FILE_TO_S3_SUCCESS,
    RESET_UPLOAD_FILE_TO_S3,
    documentDetails,
    s3posturl,
    s3error,
    s3formdata,
    FETCH_SAVE_TO_DB_REQUEST,
    savingtodb,
    avatar,
    requestingClientDocuments,
    onUploadComplete,
    uploadingToS3,
    tGUID,
    accessToken,
  } = props;
  const [showForm, setShowForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [tempS3FormData, setTempS3FormData] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [currentPosition, setCurrentPosition] = useState();

  const isDocumentApproved = documentStatusCode === "2";

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleCancel = () => {
    setShowForm(false);
    setSelectedFile(null);
    setSelectedDocumentType(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
    } else {
      console.warn("Invalid file type");
    }
  };

  useEffect(() => {
    requestUntHldDetailDocuments(avatar);
  }, []);

  const CALL_API_REQUEST_UPLOAD_IMAGE = async (position) => {
    try {
      console.log("[customerUuid]", customerUuid);
      console.log("[tGUID]", tGUID);
      console.log("[accessToken]", accessToken);

      const body = {
        customerUuid: customerUuid,
        position: position,
      };

      const url = `${
        process.env.REACT_APP_API_ADMIN_URL
      }/api/v2/me/identificationPresignedPostPolicy?fields=${encodeURIComponent(
        "formDatas,postUrl"
      )}`;

      const response = await fetch(url, {
        method: "PUT", // เปลี่ยนจาก POST เป็น PUT ตามเดิม
        headers: {
          "Content-Type": "application/json",
          "content-language": "th",
          userid: tGUID,
          id_token: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body), // แปลง body เป็น JSON string
      });

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      const presignedrequest = await response.json(); // แปลง response เป็น JSON
      console.log("[presignedrequest]", presignedrequest);

      return presignedrequest;
    } catch (error) {
      console.error("Error in CALL_API_REQUEST_UPLOAD_IMAGE:", error.message);
    }
  };

  const CALL_API_UPLOAD_IMAGE_TO_S3 = async (position, file, data, url) => {
    try {
      console.log("[CALL_API_UPLOAD_IMAGE_TO_S3] --- data :", data);
      console.log("[CALL_API_UPLOAD_IMAGE_TO_S3] --- file :", file);
      console.log("[CALL_API_UPLOAD_IMAGE_TO_S3] --- position :", position);

      if (!data || !Array.isArray(data)) {
        throw new Error(
          "Invalid s3FormData: Expected an array of [key, value]."
        );
      }

      setCurrentPosition(position);
      setImageFile(file);

      const formData = new FormData();

      // ตรวจสอบและแปลง s3FormData
      data.forEach(([key, value]) => {
        if (typeof value !== "string") {
          console.warn("Unexpected value type for key:", key, "Value:", value);
          value = String(value); // แปลงให้เป็นสตริง
        }
        formData.append(key, value);
      });

      // ตรวจสอบ file
      if (!(file instanceof Blob)) {
        throw new Error("Invalid file: The provided file is not a valid Blob.");
      }
      formData.append("file", file, `identification-${position}.jpg`);

      // ส่งข้อมูลไปที่ S3
      await fetch(url, {
        method: "POST",
        body: formData,
        mode: "no-cors",
      });

      /* if (!response.ok) {
        throw new Error("Upload to Amazon S3 failed");
      } */

      setShowForm(false);
      await FETCH_SAVE_TO_DB_REQUEST(position);

      setTimeout(() => {
        requestUntHldDetailDocuments(avatar);
        console.log("Requested new documents");
      }, 5000);

      if (onUploadComplete) {
        onUploadComplete();
      }
    } catch (error) {
      console.error("Error in CALL_API_UPLOAD_IMAGE_TO_S3:", error.message);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      const positionString = documentTypeMapping[documentTypeCode];
      const data = await CALL_API_REQUEST_UPLOAD_IMAGE(positionString);
      const newData = data.data;
      await CALL_API_UPLOAD_IMAGE_TO_S3(
        positionString,
        selectedFile,
        newData.formDatas,
        newData.postUrl
      );
    } else {
      alert("Please select both a file and a document type.");
    }
  };

  useEffect(() => {
    const resetUploadFile = async () => {
      await RESET_UPLOAD_FILE_TO_S3();
    };
    resetUploadFile();
  }, []);

  useEffect(() => {
    console.log(
      "[CURRENT-POSITION] :: ",
      documentTypeMapping[documentTypeCode]
    );
  }, [documentTypeCode]);

  return (
    <>
      <button
        className={"client-detail-profile-document-refresh"}
        onClick={handleButtonClick}
      >
        Upload File
      </button>
      {showForm && (
        <div className="upload-doc-container">
          <div className="upload-doc-modal">
            {uploadingToS3 && (
              <div className="document-circular-loader">
                <CircularProgress className="document-status-progress" />
                <p className="document-status-text-progress">
                  Saving document...
                </p>
              </div>
            )}
            <div className="upload-doc-header">
              <h1>Upload Document</h1>
            </div>

            {/* <div className="upload-doc-input-container-unitholder">
              <label htmlFor="documentType">Select Document Type</label>
              <Select
                options={documentOptions}
                placeholder="Select Document Type"
                onChange={handleChange}
                value={selectedDocumentType}
              />
            </div> */}
            <div style={{ marginTop: 50 }}></div>
            <div className="upload-doc-input-container-unitholder">
              <label htmlFor="fileInput">Select File (jpg,jpeg,png)</label>
              <div className="upload-doc-input-unitholder">
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  className="upload-doc-input-hidden"
                  onChange={(event) => {
                    handleFileChange(event);
                  }}
                />
                <label className="upload-doc-label-inner" htmlFor="fileInput">
                  <p>{selectedFile ? selectedFile.name : "No file selected"}</p>
                  <div className="upload-doc-upload-icon-unitholder">
                    Browse
                  </div>
                </label>
              </div>
            </div>
            <div style={{ marginTop: 60 }}></div>
            <div className="upload-doc-buttons">
              <button className="upload-doc-cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button
                className="upload-doc-submit"
                disabled={!selectedFile /*  || !selectedDocumentType */}
                onClick={handleUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const withConnect = connect(
  (state) => ({
    ...state.untHldDetailReducer,
    tGUID: state.auth.GUID,
    accessToken: state.auth.accessToken,
  }),
  {
    requestUntHldDetailDocuments,
    FETCH_UPLOAD_FILE_TO_S3_REQUEST,
    RESET_UPLOAD_FILE_TO_S3,
    FETCH_SAVE_TO_DB_REQUEST,
    FETCH_UPLOAD_FILE_TO_S3_SUCCESS,
  }
);

export default compose(withConnect, withRouter)(ManualUpload);
