import { createAction } from "redux-act";
export const requestUnthldDetailProfile = createAction(
  "REQUEST_UNTHLD_DETAIL_PROFILE"
);
export const receiveUnthldDetailProfile = createAction(
  "RECEIVE_UNTHLD_DETAIL_PROFILE"
);
export const failUntHldDetailProfile = createAction(
  "FAIL_UNTHLD_DETAIL__PROFILE"
);
export const requestUntHldDetailDocuments = createAction(
  "REQUEST_UNTHLD_DETAIL_DOCUMENTS"
);
export const receiveUntHldDetailDocuments = createAction(
  "RECEIVE_UNTHLD_DETAIL_DOCUMENTS"
);
export const failUntHldDetailDocuments = createAction(
  "FAIL_UNTHLD_DETAIL_DOCUMENTS"
);
export const setCurrentCustomerUntHldDetail = createAction(
  "SET_CURRENT_CUSTOMER_UNTHLD_DETAIL"
);
export const requestUntHldDetailAccountLockedStatus = createAction(
  "REQUEST_UNTHLD_DETAIL_ACCOUNT_LOCKED_STATUS"
);
export const receiveUntHldDetailAccountLockedStatus = createAction(
  "RECEIVE_UNTHLD_DETAIL_ACCOUNT_LOCKED_STATUS"
);
export const failUntHldDetailAccountLockedStatus = createAction(
  "FAIL_UNTHLD_DETAIL_ACCOUNT_LOCKED_STATUS"
);

export const requestUntHldDetailAccountUnlock = createAction(
  "REQUEST_UNTHLD_DETAIL_ACCOUNT_UNLOCK"
);
export const receiveUntHldDetailAccountUnlock = createAction(
  "RECEIVE_UNTHLD_DETAIL_ACCOUNT_UNLOCK"
);
// #region : PAGINATION
export const FETCH_TABLE_HISTORY_LOG_PAGINATION = createAction(
  "FETCH_TABLE_HISTORY_LOG_UNITHOLDER_PAGINATION"
);

export const SUCCESS_FETCH_TABLE_HISTORY_LOG_PAGINATION = createAction(
  "SUCCESS_FETCH_TABLE_HISTORY_LOG_UNITHOLDER_PAGINATION"
);

export const FAILED_FETCH_TABLE_HISTORY_LOG_PAGINATION = createAction(
  "FAILED_FETCH_TABLE_HISTORY_LOG_UNITHOLDER_PAGINATION"
);
// #endregion

export const failUntHldDetailAccountUnlock = createAction(
  "FAIL_UNTHLD_DETAIL_ACCOUNT_UNLOCK"
);

export const requestUntHldDetailDocumentApprove = createAction(
  "REQUEST_UNTHLD_DETAIL_DOCUMENT_APPROVAL"
);
export const receiveUntHldDetailDocumentApprove = createAction(
  "RECEIVE_UNTHLD_DETAIL_DOCUMENT_APPROVAL"
);
export const failUntHldDetailDocumentApprove = createAction(
  "FAIL_UNTHLD_DETAIL_DOCUMENT_APPROVAL"
);

export const requestUntHldDetailDocumentReject = createAction(
  "REQUEST_UNTHLD_DETAIL_DOCUMENT_REJECTION"
);
export const receiveUntHldDetailDocumentReject = createAction(
  "RECEIVE_UNTHLD_DETAIL_DOCUMENT_REJECTION"
);
export const failUntHldDetailDocumentReject = createAction(
  "FAIL_UNTHLD_DETAIL_DOCUMENT_REJECTION"
);

export const requestUntHldDetailDocumentUndo = createAction(
  "REQUEST_UNTHLD_DETAIL_DOCUMENT_UNDO"
);
export const receiveUntHldDetailDocumentUndo = createAction(
  "RECEIVE_UNTHLD_DETAIL_DOCUMENT_UNDO"
);
export const failUntHldDetailDocumentUndo = createAction(
  "FAIL_UNTHLD_DETAIL_DOCUMENT_UNDO"
);

export const requestUntHldDetailApprove = createAction(
  "REQUEST_UNTHLD_DETAIL_APPROVAL"
);
export const receiveUntHldDetailApprove = createAction(
  "RECEIVE_UNTHLD_DETAIL_APPROVAL"
);
export const failUntHldDetailApprove = createAction(
  "FAIL_UNTHLD_DETAIL_APPROVAL"
);

export const requestUntHldDetailReject = createAction(
  "REQUEST_UNTHLD_DETAIL_REJECTION"
);
export const receiveUntHldDetailReject = createAction(
  "RECEIVE_UNTHLD_DETAIL_REJECTION"
);
export const failUntHldDetailReject = createAction(
  "FAIL_UNTHLD_DETAIL_REJECTION"
);

export const requestUntHldDetailCancelStatus = createAction(
  "REQUEST_UNTHLD_DETAIL_CANCEL_STATUS"
);
export const receiveUntHldDetailCancelStatus = createAction(
  "RECEIVE_UNTHLD_DETAIL_CANCEL_STATUS"
);
export const failUntHldDetailCancelStatus = createAction(
  "FAIL_UNTHLD_DETAIL_CANCEL_STATUS"
);

export const requestUntHldDetailFunds = createAction(
  "REQUEST_UNTHLD_DETAIL_FUNDS"
);
export const receiveUntHldDetailFunds = createAction(
  "RECEIVE_UNTHLD_DETAIL_FUNDS"
);
export const failUntHldDetailFunds = createAction("FAIL_UNTHLD_DETAIL_FUNDS");

export const clearUntHldDetailErrorMessage = createAction(
  "CLEAR_UNTHLD_DETAIL_ERROR_MESSAGE"
);
export const receiveCustomerUntHldDetailChannel = createAction(
  "CUSTOMER_UNTHLD_DETAIL_CHANNEL"
);
// -----------------------UserAccount----------------------
export const REQUsrActSrch = createAction("REQUEST_USRACT_SRCH");
export const RESUsrActSrch = createAction("RECEIVE_USRACT_SRCH");
export const FailUsrActSrch = createAction("FAILED_USRACT_SRCH");
// -----------------------UnitHolder----------------------
export const REQUntHldSrch = createAction("REQUEST_UNTHLD_SRCH");
export const RESUntHldSrch = createAction("RECEIVE_UNTHLD_SRCH");
export const FailUntHldSrch = createAction("FAILED_UNTHLD_SRCH");
export const CleanUpUntHldSrch = createAction("CLEANUP_UNTHLD_SRCH");
export const UpdateUnitHolderId = createAction("UPDATE_UNIT_HOLDER_ID");
export const FullImagePath = createAction("GET_FULL_IMAGE");

export const getApplicants = createAction("GET_UNTHLD_APPLICANTS");
export const setApplicants = createAction("SET_UNTHLD_APPLICANTS");
export const failApplicants = createAction("FAIL_UNTHLD_APPLICANTS");

export const setFullImgUrl = createAction("SET_FULL_IMG_URL");
// regis img
export const FETCH_REGISTERED_IMAGE_REQUEST = createAction(
  "FETCH_REGISTERED_IMAGE_REQUEST"
);
export const FETCH_REGISTERED_IMAGE_SUCCESS = createAction(
  "FETCH_REGISTERED_IMAGE_SUCCESS"
);
export const FETCH_REGISTERED_IMAGE_FAILURE = createAction(
  "FETCH_REGISTERED_IMAGE_FAILURE"
);
// counter service
export const FETCH_COUNTERSERVICE_IMAGE_REQUEST = createAction(
  "FETCH_COUNTERSERVICE_IMAGE_REQUEST"
);
export const FETCH_COUNTERSERVICE_IMAGE_SUCCESS = createAction(
  "FETCH_COUNTERSERVICE_IMAGE_SUCCESS"
);
export const FETCH_COUNTERSERVICE_IMAGE_FAILURE = createAction(
  "FETCH_COUNTERSERVICE_IMAGE_FAILURE"
);
// s3
export const FETCH_UPLOAD_FILE_TO_S3_SUCCESS = createAction(
  "FETCH_UPLOAD_FILE_TO_S3_SUCCESS"
);
export const FETCH_UPLOAD_FILE_TO_S3_FAILURE = createAction(
  "FETCH_UPLOAD_FILE_TO_S3_FAILURE"
);
export const FETCH_UPLOAD_FILE_TO_S3_REQUEST = createAction(
  "FETCH_UPLOAD_FILE_TO_S3_REQUEST"
);
export const RESET_UPLOAD_FILE_TO_S3 = createAction("RESET_UPLOAD_FILE_TO_S3");
// save s3 to db
export const FETCH_SAVE_TO_DB_SUCCESS = createAction(
  "FETCH_SAVE_TO_DB_SUCCESS"
);
export const FETCH_SAVE_TO_DB_FAILURE = createAction(
  "FETCH_SAVE_TO_DB_FAILURE"
);
export const FETCH_SAVE_TO_DB_REQUEST = createAction(
  "FETCH_SAVE_TO_DB_REQUEST"
);

// RESET MODAL VALUE

export const RESET_MODAL_VALUES = createAction("RESET_MODAL_VALUES");
