import React from "react";
import Images from "../../utils/styles/images";

// start test
function formatProfitLossCell(cellValue) {
  if (cellValue > 0) {
    return (
      <div className="clients-table-cell-account-status">
        <img src={Images.statusIcon.pending} alt="pending-icon" />
        <p className="clients-table-cell-account-status-pending">{`${cellValue}(%)`}</p>
      </div>
    );
  } else if (cellValue < 0) {
    return (
      <div className="clients-table-cell-account-status">
        <img src={Images.statusIcon.pending} alt="pending-icon" />
        <p className="clients-table-cell-account-status-pending">{`${cellValue}(%)`}</p>
      </div>
    );
  } else {
    return cell;
  }
}

export function modifyCell(cell) {
  const { props } = cell;
  const columnName = props?.cell?.column?.Header || null;
  const cellValue = props?.cell?.value || null;
  const rowNumber = props?.row?.index + 1;

  const formatters = {
    "NO.": () => rowNumber,
    "FUND NAME": () => cell,
    "MARKET VALUE (THB)": () => cell,
    "COST INVESTED (THB)": () => cell,
    "LATEST NAV (THB)": () => cell,
    "NO. OF UNITS": () => cell,
    "UNREALISED PROFIT & LOSS (THB)": formatProfitLossCell,
  };

  return formatters[columnName] ? formatters[columnName](cellValue) : cell;
}
// end test

// This is just for the header cells
export function modifyCellHeader(
  cell,
  handleActionHeaderClick,
  handleSortHeader
) {
  if (cell === "SUBMISSION DATE") {
    return (
      <button
        className="clients-table-header-submission"
        onClick={() => handleActionHeaderClick(cell)}
      >
        SUBMISSION DATE
        <img src={Images.sorting.sorting} alt="sorting-icon" />
      </button>
    );
  } else if (cell === "DOC. STATUS" || cell === "ACC. STATUS") {
    return handleSortHeader ? handleSortHeader(cell) : cell;
  } else {
    return cell;
  }
}
