import React from "react";
import ExpandableText from "../../../../components/MainComponent/ExpandableText/expandable-text";

export function modifyCell(cell, handleActionClick) {
  const { props } = cell;

  let columnName =
    props && props.cell && props.cell.column && props.cell.column.Header
      ? props.cell.column.Header
      : null;
  let cellValue =
    props && props.cell && props.cell.value ? props.cell.value : null;

  if (columnName === "REMARK") {
    try {
      const formattedJson = JSON.stringify(JSON.parse(cellValue), null, 2);
      return <ExpandableText text={formattedJson} />;
    } catch (error) {
      // หาก JSON ไม่ถูกต้อง แสดงข้อความดั้งเดิม
      return <span>{cellValue}</span>;
    }
  } else {
    return cellValue;
  }
}
