import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import "./style.scss";
function ActiDetail(poPrp) {
  const { RowDataAt, history, PageCurrent, UISearch } = poPrp;

  const [isPageReload, setIsPageReload] = useState(false);
  const [isUnloading, setIsUnloading] = useState(false);

  useEffect(() => {
    const savePageState = () => {
      const dataFromList = JSON.parse(
        localStorage.getItem("currentActivityDetail")
      );
      console.log("dataFromList", dataFromList);
      localStorage.setItem(
        "currentActivityDetail",
        JSON.stringify(dataFromList)
      );
    };

    // Save initial state
    savePageState();

    const handleBeforeUnload = () => {
      savePageState();
    };

    const handlePageShow = (event) => {
      if (!event.persisted) {
        const storedData = JSON.parse(
          localStorage.getItem("currentActivityDetail")
        );
        if (storedData) {
          console.log("stored", storedData);
        }
      }
    };

    // Check for page reload using Performance API
    const navigationType =
      window.performance.getEntriesByType("navigation")[0]?.type;
    if (navigationType === "reload") {
      setIsPageReload(true);
      const storedData = JSON.parse(
        localStorage.getItem("currentActivityDetail")
      );
      console.log("StoredData from", storedData.From);
      console.log("StoredData id", storedData.RowDataAt.id);
      if (storedData) {
        console.log("storedData", storedData);
        if (storedData.From === "User") {
          history.push(`/ActivityDetail_Usr/${storedData.RowDataAt.id}`);
        } else if (storedData.From === "Admin") {
          history.push(`/ActivityDetail_Admin/${storedData.RowDataAt.id}`);
        }
      }
    }

    // Handle navigation events
    const unlisten = history.listen((location, action) => {
      if (action === "POP" && !isPageReload && !isUnloading) {
        history.replace({
          pathname:
            history.location.pathname === "Activity_Admin"
              ? "/Activity_Admin"
              : "/Activity_Usr",
          PageCurrent: PageCurrent,
          UISearch: UISearch,
        });
      }
    });

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("pageshow", handlePageShow);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("pageshow", handlePageShow);
      unlisten();

      if (!isPageReload && !isUnloading) {
        localStorage.removeItem("currentActivityDetail");
      }
    };
  }, []);
  return (
    <Fragment>
      <div className="tag-feed-form-container">
        <div className="tag-feed-form-buttons-container">
          <button
            className="tag-detail-cancel"
            onClick={() =>
              history.push({
                pathname:
                  history.location.pathname === "Activity_Admin"
                    ? "/Activity_Admin"
                    : "/Activity_Usr",
                PageCurrent: PageCurrent,
                UISearch: UISearch,
              })
            }
          >
            CANCEL
          </button>
        </div>

        <div
          className="acti-feed-form-inputs-container"
          style={{ height: 600 }}
        >
          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">First Name</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.firstName}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Last Name</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.lastName}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Mobile No.</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.mobile}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Username</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.userName}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Device Name</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.deviceName}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Customer UUID</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.customerUuid}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Firebase ID</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.firebaseFid}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">App Version</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.appVersion}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">App Platform</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.appPlatform}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">IP Address</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.ipAddress}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Created At</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.createdAt}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">Activity Type</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.activityType}</span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div style={{ display: "flex" }}>
            <span style={{ paddingRight: 160 }}>Activity Data</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span style={{ wordBreak: "break-all" }}>
              {RowDataAt.activityData}
            </span>
          </div>

          <div className="acti-feed-form-input-spacer" />
          <div className="labelInput">
            <span className="labelInputText ">E-mail</span>
            <span style={{ paddingRight: 10 }}>:</span>
            <span>{RowDataAt.email}</span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const withConnect = connect(
  (state) => ({
    ...state.actiDetailReducer,
  }),
  {}
);

export default compose(withConnect, withRouter)(ActiDetail);
