import React from "react";
import { useTable } from "react-table";
import { modifyCell, modifyCellHeader } from "./cell_formatter";
import Images from "../../utils/styles/images";

function Table({
  columns,
  data,
  handleActionClick,
  handleActionHeaderClick,
  handleSortHeader,
  pageIndex,
  pageSize,
}) {
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    data: data,
    columns: columns,
  });

  return (
    <table className="clients-table">
      <thead className="clients-head-container">
        {headerGroups.map((headerGroup) => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            className="clients-head-row"
          >
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className="clients-header">
                {modifyCellHeader(
                  column.render("Header"),
                  handleActionHeaderClick,
                  handleSortHeader
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps} className="clients-table-body">
        {rows.length <= 0 ? (
          <tr className="clients-table-empty-row">
            <td colSpan={14}>
              <div className="clients-table-no-data">
                <img src={Images.noData.board} alt="no-data-found-board" />
                <p className="clients-table-no-data-header">No result found</p>
                <p className="clients-table-no-data-body">
                  We couldn’t find what you are looking for. This client likely
                  has no funds purchased or has not yet been approved.
                </p>
              </div>
            </td>
          </tr>
        ) : (
          rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="clients-table-row">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="clients-cell">
                      {modifyCell(
                        cell.render("Cell"),
                        handleActionClick,
                        pageIndex,
                        pageSize
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
}

export default Table;
